.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-list li::before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% + 16px);
  background-color: #d1d5db;
  left: 16px;
  top: 0;
}

li:first-child::before {
  top: 50%; /* Comienza la línea desde el medio del primer círculo */
}

li:last-child::before {
  height: 50%; /* Termina la línea en el medio del último círculo */
}

/* Nuevos estilos para el banner */
.banner-container {
  padding: 0.5rem 0;
  background-color: #0f2645;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner-logo {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.banner-slogan {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  color: white;
}

.banner-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.banner-nav-item {
  padding: 0.5rem 1.5rem;
  margin-top: 0.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes typing-erasing {
  0%, 100% { width: 0 }
  50% { width: 100% }
}

.typewriter {
  color: white;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid white;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  animation: 
    typing-erasing 4s steps(12, end) infinite,
    blink-caret .75s step-end infinite;
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white }
}

.banner-logo-container {
  display: flex;
  align-items: baseline;
  gap: 0.75rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.banner-logo {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
}
