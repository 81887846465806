.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

@media (min-width: 768px) {
  .page-container {
    padding: 4rem 2rem;
  }
} 